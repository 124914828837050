<script setup lang="ts">
const { sendScreenView } = useAnalytics();

function trackView(url: string): void {
  sendScreenView({ type: "UrlClickWeb", target: url }, true);
}

const { getYear } = useUtils();
</script>

<template>
  <footer>
    <LazyTheCountdown />
    <TheSocialMenu />
    <div class="footer-content">
      <div class="footer-logos">
        <a
          href="https://www.jamestownlp.com/"
          target="_blank"
          aria-label="Go to Jamestown LP website"
          @click="trackView('JamestownWebsite')"
        >
          <nuxt-img
            :src="`${useCdnUrl()}/assets/logos/jamestown-logo.svg`"
            alt="Jamestown LP"
            width="150"
          />
        </a>
      </div>
      <div class="footer-connect">
        For general inquiries, contact
        <a
          href="mailto:connect@onetimessquare.com"
          aria-label="Email One Times Square"
          @click="trackView('Email.connect@onetimessquare')"
          >connect@onetimessquare.com</a
        >
      </div>
      <div class="privacy">
        <div>&copy;{{ getYear() }} All rights reserved Jamestown L.P.</div>
        <a
          href="https://www.jamestownlp.com/privacy-policy-and-disclaimers"
          target="_blank"
          aria-label="Read privacy policy"
          @click="trackView('JamestownPrivacyPolicy')"
          >Privacy Policy</a
        >
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
@use "@/assets/scss/queries";
footer {
  background-color: black;
  .footer-content {
    align-items: center;
    color: white;
    display: flex;
    justify-content: space-between;
    margin: auto;
    max-width: var(--device-xl);
    padding: 4rem;
    width: 100%;

    @media (max-width: 820px) {
      padding-bottom: 15rem;
    }

    @include queries.for-size(tablet-landscape-down) {
      flex-direction: column;
      font-size: 2rem;
      gap: 2rem;
      div {
        display: flex;

        flex-direction: column !important;
        text-align: center;
        width: 100%;
      }

      .footer-connect {
        font-size: 2rem !important;
      }
    }

    .footer-logos {
      align-items: center;
      display: inline-flex;
      font-size: 1.25rem;
      gap: 6rem;

      img {
        height: 75px;
        width: auto;
      }

      @include queries.for-size(phone-only) {
        display: flex;
        flex-direction: row !important;
        gap: 5rem;
        justify-content: center;
        padding: 3rem;
        width: 100%;

        img {
          height: 40px;
        }
      }
    }

    .footer-connect {
      font-size: 1.6rem;
    }

    .privacy {
      display: flex;
      flex-direction: row;
      font-size: 1.25rem;
      gap: 2rem;
      margin-top: 1rem;

      a {
        text-decoration: underline !important;
        &:visited {
          color: white;
        }
      }
    }
  }
}
</style>
