<script setup lang="ts">
import { useAppVersion } from "./composables/app-version";

// useState("use-black-logo", () => false);

const { appName } = useEnvPaths();

const { isConsent, sendScreenView } = useAnalytics();
const consent = useCookie(`${appName()}_cookieConsent`);

// Logs app version to console
const appVersion = await useAppVersion();

onMounted(() => {
  window.scrollTo(0, 1);

  const { checkGdprLocale } = useGdprStore();
  checkGdprLocale();

  const { getZenApiUrl } = useEnvPaths();

  const analyticsOptions = {
    apiUrl: `${getZenApiUrl() as string}/events`,
    appId: "ots",
    userId: "",
    tenant: "ots",
    appName: appName() as string,
    useZenAnalytics: useRuntimeConfig().public.analyticsEnabled,
    useGtag: useRuntimeConfig().public.analyticsEnabled,
    gtag: {
      enabled: useRuntimeConfig().public.nodeEnv === "production",
      config: {
        id: useRuntimeConfig().public.gaTagIds,
      },
    },
    appReleaseNumber: appVersion.version,
    useageType: "device",
    eventId: "",
    useZenClientHeader: false,
  };

  useAnalytics(analyticsOptions);

  isConsent.value = !!consent.value;

  const route = useRoute();

  sendScreenView({
    type: "FirstWebStart",
    target: route.params.id || route.name,
  });
});

function onCookieConsentAccepted(): void {
  isConsent.value = true;
  consent.value = "true";
  sendScreenView({ type: "GDPR", target: "ConsentAllowed" });
}
</script>

<template>
  <TheHeader />
  <NuxtPage />
  <TheFooter />
  <LazyConsentFooter v-if="!isConsent" role="dialog" @accepted="onCookieConsentAccepted" />
</template>
