export const useEnvPaths = () => {
  const config = useRuntimeConfig();

  const getZenApiUrl = () => {
    return config.public.zenApi;
  };

  const getAnalyticsUrl = () => {
    return config.public.zenAnalytics;
  };

  const getGAIds = () => {
    const path = config.public.gaTagIds as string;
    return path.split(",");
  };

  const appName = () => {
    return config.public.appName;
  };

  return {
    getZenApiUrl,
    getAnalyticsUrl,
    getGAIds,
    appName,
  };
};
